(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/constants.js');
"use strict";


const DRAW_CARD_LAYOUTS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};
const DRAW_CARD_ICON_SIZE = {
  LARGE: '300',
  SMALL: '200',
  MINI: '100'
};
const DRAW_CARD_TITLES = {
  BET_STOP: 'Spelstopp',
  DRAW_DESCRIPTION: 'Omgång',
  EXTRA_MONEY: 'Extrapengar',
  JACKPOT: 'Jackpot',
  LEAGUE: 'Liga',
  NET_SALE: 'Omsättning',
  ROLL_OVER_IN: 'Rullpott',
  STATE: 'Status'
};
const DRAW_STATE = {
  CANCELLED: 'cancelled',
  IS_LIVE: 'isLive',
  NOT_OPENED: 'notOpened',
  TEMPORARY_CLOSED: 'temporaryClosed',
  FINISHED: 'finished'
};
const DRAW_STATE_TEXTS = {
  CANCELLED: 'Inställd omgång',
  NOT_OPENED: 'Omgång ej startad',
  FINISHED: 'Avslutad'
};
const DRAW_CARD_VARIANTS = {
  MINI: 'draw-card-mini',
  SMALL: 'draw-card-small',
  LARGE: 'draw-card-large',
  HORIZONTAL_LIST_ITEM: 'draw-card-horizontal-list-item'
};
setGlobal('svs.components.tipsen.drawCard.constants', {
  DRAW_CARD_ICON_SIZE,
  DRAW_CARD_LAYOUTS,
  DRAW_CARD_TITLES,
  DRAW_STATE,
  DRAW_STATE_TEXTS,
  DRAW_CARD_VARIANTS
});

 })(window);