(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-container.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-container.js');
"use strict";


const {
  DrawCardLarge,
  DrawCardSmall,
  DrawCardMini,
  DrawCardHorizontalListItem
} = svs.components.tipsen.drawCard;
const {
  useComposeDrawForDrawCard
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const {
  If
} = svs.components.lbUtils.reactConditionals;
const {
  clx
} = svs.components.lbUtils;
const {
  productIds
} = svs.utils.products;
const {
  ParticipantsNames,
  constants: {
    DescriptionSize
  }
} = svs.components.lbUi.participants;
const DrawCardContainer = _ref => {
  let {
    branding,
    cardClassName,
    containerClassName,
    countdownString,
    currentDraw,
    drawCardVariant,
    isListItem = false,
    isSelected,
    onToggleDrawSelector,
    shouldShowChangeDrawButton = false
  } = _ref;
  const blockName = 'pg_draw_card_container';
  const classNames = clx(blockName, containerClassName);
  const composedDraw = useComposeDrawForDrawCard(currentDraw);
  const title = composedDraw.competitionName ? composedDraw.competitionName : composedDraw.title;
  let titleElement = title;
  if (composedDraw.productId === productIds.MATCHEN) {
    var _composedDraw$betEven, _composedDraw$betEven2;
    const homeParticipant = (_composedDraw$betEven = composedDraw.betEvents[0]) === null || _composedDraw$betEven === void 0 ? void 0 : _composedDraw$betEven.homeParticipant;
    const awayParticipant = (_composedDraw$betEven2 = composedDraw.betEvents[0]) === null || _composedDraw$betEven2 === void 0 ? void 0 : _composedDraw$betEven2.awayParticipant;
    titleElement = React.createElement(ParticipantsNames, {
      awayParticipant: awayParticipant,
      homeParticipant: homeParticipant,
      nameSize: DescriptionSize.Full
    });
  }
  return React.createElement("div", {
    className: classNames
  }, React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.LARGE
  }, React.createElement(DrawCardLarge, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    competitionDrawNumber: composedDraw.competitionDrawNumber,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onToggleDrawSelector: onToggleDrawSelector,
    regCloseTime: composedDraw.regCloseTime,
    shouldShowChangeDrawButton: shouldShowChangeDrawButton,
    state: composedDraw.state,
    titleElement: titleElement,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: !isListItem && drawCardVariant === DRAW_CARD_VARIANTS.SMALL
  }, React.createElement(DrawCardSmall, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    competitionDrawNumber: composedDraw.competitionDrawNumber,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onToggleDrawSelector: onToggleDrawSelector,
    productId: composedDraw.productId,
    regCloseTime: composedDraw.regCloseTime,
    shouldShowChangeDrawButton: shouldShowChangeDrawButton,
    state: composedDraw.state,
    titleElement: titleElement,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.MINI
  }, React.createElement(DrawCardMini, {
    branding: branding,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    regCloseTime: countdownString || composedDraw.regCloseTime,
    state: composedDraw.state,
    titleElement: composedDraw.title,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.HORIZONTAL_LIST_ITEM
  }, React.createElement(DrawCardHorizontalListItem, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    className: cardClassName,
    competitionDrawNumber: composedDraw.competitionDrawNumber,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    isSelected: isSelected,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    regCloseTime: composedDraw.regCloseTime,
    state: composedDraw.state,
    titleElement: titleElement,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: isListItem && drawCardVariant === DRAW_CARD_VARIANTS.SMALL
  }, React.createElement(DrawCardSmall, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    className: cardClassName,
    competitionDrawNumber: composedDraw.competitionDrawNumber,
    currentDraw: currentDraw,
    drawNumber: composedDraw.drawNumber,
    extraMoney: composedDraw.extraMoney,
    href: composedDraw.href,
    icon: composedDraw.icon,
    isDisabled: composedDraw.isDisabled,
    isListItem: true,
    isSelected: isSelected,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onToggleDrawSelector: onToggleDrawSelector,
    participants: composedDraw.participants,
    productId: composedDraw.productId,
    regCloseTime: composedDraw.regCloseTime,
    state: composedDraw.state,
    titleElement: titleElement,
    uniqueId: composedDraw.drawId
  })));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardContainer', DrawCardContainer);

 })(window);