(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-small.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-small.js');
"use strict";


const {
  ReactButton
} = svs.ui;
const {
  clx
} = svs.components.lbUtils;
const {
  DrawCard,
  useConditionalWrapper
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_TITLES,
  DRAW_CARD_ICON_SIZE,
  DRAW_STATE,
  DRAW_STATE_TEXTS
} = svs.components.tipsen.drawCard.constants;
const {
  DisabledOverlay
} = svs.components.tipsen.drawCard.components;
const {
  SpeechBubble,
  SpeechBubbleSport3
} = svs.ui.ReactSpeechBubble;
const {
  productIds
} = svs.utils.products;
const hasReactChildren = children => {
  if (Array.isArray(children)) {
    return children.some(child => Boolean(child));
  }
  return Boolean(children);
};
const DrawCardSmallSection = _ref => {
  let {
    children,
    className
  } = _ref;
  if (!hasReactChildren(children)) {
    return null;
  }
  return React.createElement("div", {
    className: clx('pg_draw_card_small__section', className)
  }, children);
};
const BetEventsSection = _ref2 => {
  let {
    betEvents
  } = _ref2;
  return React.createElement(DrawCardSmallSection, {
    className: "pg_draw_card_small__section--footer grid-span-2-cols"
  }, React.createElement(DrawCard.BetEvents, {
    hasScrolling: true
  }, betEvents));
};
const DrawCardSmall = _ref3 => {
  let {
    branding,
    className,
    competitionDrawNumber,
    extraMoney,
    icon = 'game-soccer',
    isListItem = false,
    league,
    netSale,
    onToggleDrawSelector,
    isSelected,
    betEvents,
    regCloseTime,
    shouldShowChangeDrawButton = false,
    state,
    titleElement,
    uniqueId,
    productId
  } = _ref3;
  const ConditionalDisabledOverlayWrapper = useConditionalWrapper(DisabledOverlay, {
    skip: state !== DRAW_STATE.TEMPORARY_CLOSED
  });
  const isBombenOrMatchen = productId === productIds.MATCHEN || productId === productIds.BOMBEN;
  const extraMoneyOptions = React.useMemo(() => [{
    displayCondition: extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney,
    title: DRAW_CARD_TITLES.EXTRA_MONEY
  }, {
    displayCondition: extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isRolloverIn,
    title: DRAW_CARD_TITLES.ROLL_OVER_IN
  }, {
    displayCondition: extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isJackpot,
    title: DRAW_CARD_TITLES.JACKPOT
  }], [extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney, extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isJackpot, extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isRolloverIn]);
  const blockName = 'pg_draw_card_small';
  const classNames = clx(blockName, {
    'pg_draw_card--selected': isSelected,
    'pg_draw_card_small--list-item': isListItem
  }, className);
  const shouldShowSpeechBubble = Boolean((extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney) && isListItem);
  const headerSectionClassName = clx('pg_draw_card_small__section--header', shouldShowSpeechBubble && 'pg_draw_card_small__section--header_with_speech_bubble');
  return React.createElement(ConditionalDisabledOverlayWrapper, null, React.createElement(DrawCard, {
    branding: branding,
    className: classNames
  }, React.createElement(DrawCardSmallSection, {
    className: headerSectionClassName
  }, React.createElement(DrawCard.Title, {
    icon: icon,
    iconSize: DRAW_CARD_ICON_SIZE.SMALL,
    isSelected: isSelected
  }, titleElement), shouldShowSpeechBubble && React.createElement(SpeechBubble, {
    className: "pg_draw_card_speech_bubble",
    size: "100"
  }, React.createElement(SpeechBubbleSport3, null, "".concat(DRAW_CARD_TITLES.EXTRA_MONEY, ": ").concat(extraMoney.amount))), !isBombenOrMatchen && React.createElement(DrawCard.League, {
    className: shouldShowSpeechBubble ? 'pg_draw_card_small__section--header_league' : ''
  }, league)), React.createElement(DrawCardSmallSection, {
    className: "pg_draw_card_small__section--body"
  }, isBombenOrMatchen && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.LEAGUE), React.createElement(DrawCard.League, null, league)), competitionDrawNumber > 0 && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.DRAW_DESCRIPTION), React.createElement(DrawCard.CompetitionDrawNumber, null, competitionDrawNumber)), regCloseTime && state !== DRAW_STATE.CANCELLED && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.BET_STOP), React.createElement(DrawCard.RegCloseTime, null, regCloseTime)), state === DRAW_STATE.NOT_OPENED && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.STATE), React.createElement(DrawCard.Status, null, DRAW_STATE_TEXTS.NOT_OPENED)), state === DRAW_STATE.CANCELLED && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.STATE), React.createElement(DrawCard.Status, {
    isCancelled: true
  }, DRAW_STATE_TEXTS.CANCELLED)), netSale && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.NET_SALE), React.createElement(DrawCard.NetSale, {
    currencyCounterUniqueId: uniqueId
  }, netSale)), extraMoneyOptions.map(extraMoneyOption => extraMoneyOption.displayCondition && React.createElement(React.Fragment, {
    key: extraMoneyOption.title
  }, React.createElement("div", null, extraMoneyOption.title), React.createElement(DrawCard.ExtraAmount, null, extraMoney.amount))), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoneyAndJackpot) && React.createElement(React.Fragment, null, React.createElement("div", null, DRAW_CARD_TITLES.EXTRA_MONEY), React.createElement(DrawCard.ExtraAmount, null, extraMoney.amountForExtraMoneyWithJackpot))), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isGuaranteeJackpot) && React.createElement(DrawCardSmallSection, {
    className: "pg_draw_card_small__section--center pg_draw_card_component"
  }, "En ensam vinnare \xE4r garanterad ".concat(extraMoney.amount, " kr")), React.createElement(BetEventsSection, {
    betEvents: betEvents
  }), React.createElement(DrawCardSmallSection, {
    className: "pg_draw_card_small__section--footer grid-span-2-cols pg_draw_card_section--exclude-from-overlay"
  }, shouldShowChangeDrawButton && onToggleDrawSelector && React.createElement(DrawCard.ChangeDraw, null, React.createElement(ReactButton, {
    block: true,
    inverted: true,
    onClick: onToggleDrawSelector,
    size: 300
  }, "Byt omg\xE5ng"))), isListItem && React.createElement(DrawCard.DrawToggleBtn, {
    className: "pg_draw_card_horizontal_list_item__change_draw_btn",
    isSelected: isSelected,
    isSmallDrawCard: true
  })));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardSmall', DrawCardSmall);

 })(window);